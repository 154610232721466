import { createStore } from "vuex";
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

export default createStore({
  state: {
    provider: null,
    message: "",
    walletConnected: false,
    connection: null,
    isSubmitting: false,
    amountToSend: null,
    amountToWithdraw: null,
    graphFilter: "last-15-days",
    tokenValue: 0,
    solValue: 0,
    lastTimeFetched: new Date(),
    connectedWalletKey: "",
    dateEpochFilter: "Date",
    epochSeconds: 0,
    epochValue: 0,
    refetch: false,
    isApiDown: false,
  },
  mutations: {
    setWalletConnected(state, payload) {
      state.walletConnected = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setGraphFilter(state, payload) {
      state.graphFilter = payload;
    },
    setDateEpochFilter(state, payload) {
      state.dateEpochFilter = payload;
    },
    setTokenValue(state, payload) {
      state.tokenValue = payload;
    },
    setSolValue(state, payload) {
      state.solValue = payload;
    },
    setLastTimeFetched(state, payload) {
      state.lastTimeFetched = payload;
    },
    setConnectedWalletKey(state, payload) {
      state.connectedWalletKey = payload;
    },
    setAmountToSend(state, payload) {
      state.amountToSend = payload;
    },
    setEpochSeconds(state, payload) {
      state.epochSeconds = payload;
    },
    setEpochValue(state, payload) {
      state.epochValue = payload;
    },
    setRefetch(state, payload) {
      state.refetch = payload;
    },
    setIsApiDown(state, payload) {
      state.isApiDown = payload;
    },
  },
  actions: {
    async connect_wallet({ commit, state }) {
      commit("setMessage", "");
      if (!state.provider) {
        window.open("https://phantom.app/", "_blank");
        return;
      }
      try {
        await state.provider.connect();
        commit("setWalletConnected", true);
        commit("setConnectedWalletKey", state.provider.publicKey.toString());

        console.log("Connected to wallet");
      } catch (err) {
        commit("setWalletConnected", false);
        console.log(
          "User cancelled connect request or wallet needs to be created",
          err
        );
        if (err.code === 4001) {
          commit("setMessage", "User rejected the request");
        } else {
          commit("setMessage", "Error occurred while connecting wallet.");
        }
      }
    },
  },
  modules: {},
});
